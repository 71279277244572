/* Container for the whole board */
.board {
  padding: 20px;
  max-width: 1200px; /* Increase width to fit more items */
  margin: 80px auto 20px auto;
}

/* Styling for the form */
form {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}

label {
  margin-bottom: 10px;
}

textarea.area {
  width: 100%;
  height: 100px;
  padding: 10px;
  margin-top: 5px;
  border: 1px solid #ddd;
  border-radius: 4px;
  resize: vertical;
}

input[type="file"] {
  margin-top: 5px;
}

button[type="submit"] {
  padding: 10px 20px;
  background-color: #1a4bd3;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-top: 10px;
}

.savebutton{
  width:20%;
}

button[type="submit"]:hover {
  background-color: #0d0857;
}

/* Styling for notices list */
.notice-list {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr)); /* Adjust size as needed */
  gap: 10px; /* Space between items */
  margin-top: 20px;
}

.notice-item {
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 10px;
  background-color: #f9f9f9;
  text-align: center; /* Center align text and image */
}

.notice-item img {
  max-width: 100%; /* Ensure image fits within the container */
  height: auto;    /* Maintain aspect ratio */
  border-radius: 4px;
}

.notice-item h3 {
  margin: 10px 0;
}

/* Styling for buttons */
.delete-button {
  background-color: #f44336;
  color: white;
  border: none;
  padding: 5px 10px;
  cursor: pointer;
  margin-top: 10px;
  border-radius: 4px;
}

.delete-button:hover {
  background-color: #d32f2f;
}

/* Styling for show more/less buttons */
.show-buttons {
  margin-top: 20px;
}

.show-buttons button {
  padding: 10px 20px;
  background-color: #2196F3;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-right: 10px;
}

.show-buttons button:hover {
  background-color: #1976D2;
}

/* Styling for success and error messages */
.success-message,
.error-message {
  padding: 10px;
  margin-top: 20px;
  border-radius: 4px;
  color: white;
}

.success-message {
  background-color: #4CAF50;
}

.error-message {
  background-color: #f44336;
}

/* Styling for the loading animation */
.full-page-loader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.pulsing-dot {
  width: 50px;
  height: 50px;
  background-color: white;
  border-radius: 50%;
  animation: pulse 1.5s infinite;
}

@keyframes pulse {
  0% {
    transform: scale(1);
    opacity: 1;
  }
  50% {
    transform: scale(1.5);
    opacity: 0.5;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}
