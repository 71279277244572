

.officer-home-container {
  max-width: 100%;
  margin: auto;
  padding: 80px;
  text-align: center;
  background-image: url('../../../public/images/WhatsApp\ Image\ 2024-02-01\ at\ 14.57.55_0b5938a6.jpg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
  min-height: 100vh;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.welcome-section{
  padding-top: 80px;
}

.overlay {
  background: rgba(255, 255, 255, 0.9); /* White background with less transparency */
  border-radius: 8px;
  padding: 20px;
  width: 80%; /* Adjust the width as needed */
  max-width: 800px; /* Set a maximum width for better readability */
}

.welcome-header {
  color: #333; /* Adjust the text color for better visibility */
  font-size: 36px;
  margin-bottom: 20px;
}

.welcome-text {
  color: #333; /* Adjust the text color for better visibility */
  font-size: 18px;
  line-height: 1.5;
}

.associated-data {
  margin-top: 40px;
}

.associated-data h2 {
  color: #333; /* Adjust the text color for better visibility */
  font-size: 24px;
  margin-bottom: 10px;
}

.associated-data p {
  color: #333; /* Adjust the text color for better visibility */
  font-size: 16px;
  margin-bottom: 8px;
}

/* Button styles */
.button {
  font-family: 'Roboto', sans-serif;
  background-color: #007bff;
  color: #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
}

button:hover {
  background-color: #0056b3;
}

/* Loading spinner */
.loading-spinner {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px;
}

.loading-spinner p {
  font-size: 1.5rem;
  color: #007bff;
}
/* Button positioning */
.approval-button {
  position: absolute;
  top: 100px;  /* Distance from the top of the container */
  right: 20px; /* Distance from the right of the container */
  padding: 10px 20px;
  background-color: #007bff; /* Button background color */
  color: white; /* Button text color */
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.approval-button:hover {
  background-color: #0056b3; /* Darker background on hover */
}

/* Additional styles for interactive and attractive elements can be added here */