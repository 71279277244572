/* CSS for the About Us page */
.about-us-page {
    max-width: 800px;
    margin: 0 auto;
    padding: 10px;
    box-sizing: content-box;
  }
  
  .about-us-page h1 {
    text-align: center;
    font-size: 24px;
    margin-bottom: 20px;
    margin-top: 10px;
  }
  
  .about-us-page p {
    margin-bottom: 10px;
    font-size: 15px;
    text-align: justify;
  }
  
  .about-us-page img {
    width: 100%;
    max-height: 300px;
    object-fit: cover;
    margin-bottom: 20px;
  }
  
  .about-us-page table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 20px;
  }
  
  .about-us-page table th,
  .about-us-page table td {
    padding: 8px;
    border: 1px solid #ccc;
  }
  
  .about-us-page table th {
    font-weight: bold;
    text-align: left;
    background-color: #f2f2f2;
  }
  
  .about-us-page table tbody tr:nth-child(even) {
    background-color: #f9f9f9;
  }
  