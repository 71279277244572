/* OfficerPage.css */

.officer-page-container {
    margin: 100px 20px 20px;
    padding: 20px;
    font-family: Arial, sans-serif;
}

.Officertable {
    width: auto;
    border-collapse: collapse;
    margin: 20px ;
    font-size: 0.9rem;
    overflow-x: auto; /* Allow horizontal scrolling for small screens */
}

.Officertable, th, td {
    border: 1px solid #ddd;
}

th, td {
    padding: 10px;
    text-align: left;
}

th {
    background-color: #f4f4f4;
    font-weight: bold;
}

tr:nth-child(even) {
    background-color: #f9f9f9;
}

tr:hover {
    background-color: #f1f1f1;
}

/* Responsive CSS for small screens */
@media screen and (max-width: 768px) {
    .officer-page-container {
        margin: 20px 10px;
        padding: 10px;
    }

    .Officertable {
        font-size: 0.8rem; /* Adjust font size for better readability */
    }

    th, td {
        padding: 8px; /* Reduce padding for more compact display */
    }
}
