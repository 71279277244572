.aloading-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh; /* Full viewport height */
  background-color: #f8f9fa; /* Light background color */
}

.aloading-spinner {
  border: 8px solid #f3f3f3; /* Light gray border */
  border-top: 8px solid #2f6c97; /* Blue border on top */
  border-radius: 50%;
  width: 60px;
  height: 60px;
  animation: spin 1.5s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.aloading-container p {
  margin-top: 20px;
  font-size: 1.2em;
  color: #555;
}
