
/* Section for HOD list */
.hod-list-container {
  margin: 100px 20px 20px;
  padding: 20px;
  font-family: Arial, sans-serif;
}

.hod-list-container h3 {
  margin-bottom: 10px;
}


.hod-item {
  border: 1px solid #ddd;
  padding: 10px;
  margin-bottom: 10px;
  margin-left: 20px;
  border-radius: 4px;
  background-color: #f9f9f9;
}

.deassign-button {
  background-color: #d9534f;
  color: white;
  border: none;
  padding: 5px 10px;
  border-radius: 4px;
  cursor: pointer;
}

.deassign-button:hover {
  background-color: #c9302c;
}

/* Add HOD Button */
.add-hod-button {
  background-color: #5bc0de;
  color: white;
  border: none;
  margin-left: 20px;
  padding: 10px 15px;
  border-radius: 4px;
  cursor: pointer;
  margin-bottom: 20px;
}

.add-hod-button:hover {
  background-color: #31b0d5;
}

/* Add HOD Form styling */
.add-hod-form {
  border: 1px solid #ddd;
  padding: 20px;

  border-radius: 4px;
  margin-bottom: 20px;
  background-color: #f9f9f9;
}

.add-hod-form h3 {
  margin-bottom: 20px;
}

.add-hod-form form input,
.add-hod-form form select {
  display: block;
  width: 100%;
  padding: 8px;
  margin-bottom: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.add-hod-form button {
  background-color: #5bc0de;
  color: white;
  border: none;
  margin-left: 20px;
  padding: 10px 15px;
  border-radius: 4px;
  cursor: pointer;
  margin-right: 10px;
}

.add-hod-form button:hover {
  background-color: #31b0d5;
}

.cancel-button {
  background-color: #d9534f;
}

.cancel-button:hover {
  background-color: #c9302c;
}

/* Teacher List Section */
.teacher-list-container {
  margin-top: 20px;
  margin-left: 30px;
}

.teacher-list-container h3 {
  margin-bottom: 10px;
}

.teacher-list-container table {
  width: 100%;
  border-collapse: collapse;
}

.teacher-list-container th,
.teacher-list-container td {
  border: 1px solid #ddd;
  padding: 10px;
  text-align: left;
}

.teacher-list-container th {
  background-color: #f2f2f2;
}

.teacher-list-container tr:nth-child(even) {
  background-color: #f9f9f9;
}

.assign-button {
  background-color: #5bc0de;
  color: white;
  border: none;
  padding: 5px 10px;
  border-radius: 4px;
  cursor: pointer;
}

.assign-button:hover {
  background-color: #31b0d5;
}

.success-message {
  color: #5bc0de;
  margin-top: 10px;
}