/* CertificateRequestsPage.css */

.certificate-requests-page {
   
    margin: 0 auto;
    max-width: 800px;
    padding: 80px 20px 20px 20px;
    border-radius: 8px;
  }
  
  .certificate-requests-page h2 {
    font-size: 24px;
    margin-bottom: 20px;
  }
  
  .certificate-requests-page ul {
    list-style: none;
    padding: 20px;
  }
  
  .certificate-requests-page li {
    margin-bottom: 20px;
    border: 1px solid #ddd;
    padding: 10px;
    border-radius: 8px;
  }
  
  .certificate-requests-page h3 {
    font-size: 20px;
    margin-bottom: 10px;
  }
  
  .certificate-requests-page p {
    font-size: 15px;
    margin: 5px 0;
  }
  
  .certificate-requests-page button {
    background-color: #4caf50;
    color: white;
    padding: 8px 12px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
    margin-top: 10px;
  }
  
  .certificate-requests-page button:hover {
    background-color: #45a049;
  }
  
  .certificate-requests-page p.error-message {
    color: #a94442;
    margin-top: 15px;
  }
  