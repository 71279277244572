/* StudentPage.css */

.student-display-container {
  margin: 100px 20px 20px;
  padding: 20px;
  font-family: Arial, sans-serif;
}

.filter-container {
  display: flex;
  gap: 20px;
  align-items: center;
  margin-bottom: 20px;
}

.filters {
  display: flex;
  gap: 20px;
  align-items: center;
}

.filters label {
  font-size: 0.9rem;
}

.filters select {
  padding: 5px;
  font-size: 1rem;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.studenttable {
  width: 100%;
  
  margin: 20px 0;
  font-size: 0.9rem;
  overflow-x: auto; /* Add horizontal scrolling for small screens */
}

.studenttable, th, td {
  border: 1px solid #ddd;
}

th, td {
  padding: 10px;
  text-align: left;
}

th {
  background-color: #f4f4f4;
  font-weight: bold;
}

tr:nth-child(even) {
  background-color: #f9f9f9;
}

tr:hover {
  background-color: #f1f1f1;
}

/* Responsive CSS for small screens */
@media screen and (max-width: 768px) {
  .student-display-container {
    margin: 20px 10px;
    padding: 10px;
  }

  .filter-container, .filters {
    flex-direction: column; /* Stack the filter elements vertically */
    gap: 10px; /* Reduce gap for better spacing */
  }

  .filters label, .filters select {
    font-size: 0.85rem;
  }

  .studenttable, th, td {
    font-size: 0.8rem; /* Adjust font size for readability on small screens */
  }

  th, td {
    padding: 8px; /* Reduce padding to fit the content better on small screens */
  }
}
