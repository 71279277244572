.contact-us {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    font-family: Arial, sans-serif;
  }
  
  .contact-us h1 {
    text-align: center;
    font-size: 24px;
    margin-bottom: 20px;
  }
  
  .contact-us h2 {
    font-size: 20px;
    margin-top: 30px;
  }
  
  .contact-us ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
  }
  
  .contact-us li {
    margin-bottom: 10px;
  }
  
  .contact-us a {
    color: #323b45;
    text-decoration: none;
  }
  
  .contact-us button {
    margin-left: 10px;
    padding: 5px 10px;
    background-color: #414345;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .contact-us button:hover {
    background-color: #828588;
  }
  
  .contact-us p {
    font-size: 15px;
    margin-bottom: 10px;
    text-align: left;
  }
  .map-container {
    width: 100%;
    height: 400px;
    margin-top: 20px;
  }
  
  