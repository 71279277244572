.hero-container {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #000;
  overflow: hidden;
  padding-top: 80px;
}

.hero-container video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.arrow-container {
  position: absolute;
  bottom: 20px; /* Adjusted from 20px to 40px */
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.arrow-icon {
  font-size: 2rem;
  color: white;
  animation: bounce 2s infinite;
}

.arrow-text {
  color: white;
  font-size: 1rem;
  margin-top: 5px;
}

@keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-10px);
  }
  60% {
    transform: translateY(-5px);
  }
}
