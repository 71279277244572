.dashboard-container {
  padding: 100px 20px 20px 20px;
  max-width: 1200px;
  margin: 0 auto;
}

.student-details {
  background-color: #f0f0f0;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.student-info {
  display: flex;
  gap: 20px;
}

.student-image {
  flex: 0 0 200px;
}

.student-photo {
  width: 100%;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.student-table-container {
  flex: 1;
  overflow-x: auto;
}

.student-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 10px;
  background-color: #fff;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.student-table td,
.student-table th {
  padding: 12px 15px;
  text-align: left;
  border-bottom: 1px solid #ddd;
}

.student-table .table-heading {
  font-weight: bold;
  background-color: #f0f0f0;
}

.toggle-details-btn {
  margin-top: 10px;
  padding: 10px 20px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.toggle-details-btn:hover {
  background-color: #0056b3;
}

.loading-text,
.error-text {
  text-align: center;
  font-size: 18px;
  margin-top: 20px;
}

.loading-text {
  color: #007bff;
}

.error-text {
  color: #dc3545;
}

@media (max-width: 768px) {
  .student-info {
    flex-direction: column;
  }
  .student-image {
    width: 100%;
    margin-bottom: 20px;
  }
}
