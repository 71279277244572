.students-table {
  margin-top: 0px;
  width: 100%;
  border-collapse: collapse;
}

.data-table-container{
  margin: 100px 20px 20px 20px;

}

.data-table-container h1{
  font-size: larger;
}

.name-column, .actions-column {
  padding: 10px;
  text-align: left;
  border-bottom: 2px solid #ddd;
}

.name-cell, .actions-cell {
  padding: 10px;
}


.approve-btn, .decline-btn, .print-preview-btn,.Edit-btn{
  cursor: pointer;
  padding: 6px 10px;
  margin-right: 5px;
  border: none;
  border-radius: 4px;
}
.Edit-btn {
  background-color: rgb(88, 103, 120);
  color: #fff;
}

.approve-btn {
  background-color: rgb(44, 135, 239);
  color: #fff;
}

.decline-btn {
  background-color: #000000;
  color: #fff;
}

.print-preview-btn {
  background-color: #676d68;
  color: #fff;
}

.approve-btn:hover, .decline-btn:hover, .print-preview-btn:hover {
  opacity: 0.8;
}
h1 {
  font-family: 'Arial', sans-serif;
  font-size: 2rem;
  font-weight: 700;
  color: #333;
  text-align: center;
  margin-top: 20px;
  margin-bottom: 20px;
  text-transform: uppercase;
}
.no-admissions-message {
  text-align: center;
  margin-top: 50px;
  padding: 20px;
  background-color: #f9f9f9;
  border: 2px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  font-family: 'Arial', sans-serif;
  color: #333;
}

.no-admissions-message h2 {
  font-size: 2rem;
  font-weight: 600;
  color: #444;
  margin: 0;
  padding: 0;
}

.no-admissions-message .emoji {
  font-size: 3rem;
  display: block;
  margin-bottom: 10px;
}

.no-admissions-message .description {
  font-size: 1.25rem;
  margin-top: 10px;
}

@media (max-width: 768px) {
  .no-admissions-message {
    padding: 15px;
  }

  .no-admissions-message h2 {
    font-size: 1.5rem;
  }

  .no-admissions-message .emoji {
    font-size: 2.5rem;
  }
}





@media print {
  .hide-on-print {
    display: inline-block;
  }

  .approve-btn, .decline-btn, .print-preview-btn, .Edit-btn{
    display: none;
  }
}
