/* General Container Styling */
.student-details-container {
  padding: 20px;
  background-color: #f9f9f9;
}

/* Filter Section Styling */
.filter-container {
  display: flex;
  flex-direction: column; /* Stack filter sections vertically */
  gap: 15px; /* Space between the two filter lines */
}

/* Line for Department and Semester */
.department-semester-line {
  display: flex;
  gap: 15px; /* Space between department and semester */
  align-items: center; /* Align items vertically centered */
}

/* Line for Installment, Date, and Button */
.installment-date-button-line {
  display: flex;
  gap: 15px; /* Space between installment, date, and button */
  align-items: center; /* Align items vertically centered */
}

/* Filter Element Styling */
.filter-element {
  display: flex; /* Flex container for label and input */
  flex-direction: column; /* Stack label above input */
  min-width: 120px; /* Minimum width for consistency */
}

/* Input and Button Styles */
.filter-container select, 
.filter-container input {
  padding: 5px 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 14px;
  width: 100%; /* Full width for better mobile visibility */
}

.send-all-button {
  padding: 10px 20px;
  background-color: #28a745;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  min-width: 150px; /* Set a minimum width for the button */
}

.send-all-button:hover {
  background-color: #218838;
}

/* Table Styling */
.student-details-table {
  width: 100%;
  border-collapse: collapse;
  background-color: white;
}

.student-details-table thead {
  background-color: #007bff;
  color: rgb(0, 0, 0);
}

.student-details-table th, 
.student-details-table td {
  padding: 10px;
  border: 1px solid #ddd;
  text-align: center;
  font-size: 14px;
}

.student-details-table th {
  font-weight: bold;
}

.student-details-table tbody tr:nth-child(even) {
  background-color: #f2f2f2;
}

.student-details-table tbody tr:hover {
  background-color: #e9ecef;
}

/* Fee Installment Section */
.fee-table {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.installment-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
}

.paid-text {
  color: green;
  font-weight: bold;
}

.pay-button,
.email-button {
  padding: 5px 10px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 12px;
}

.pay-button:disabled {
  background-color: #6c757d;
  cursor: not-allowed;
}

.pay-button:hover:not(:disabled),
.email-button:hover {
  background-color: #0056b3;
}

/* Responsive Design */

/* For smaller screens - 768px or less */
@media (max-width: 768px) {
  .filter-container {
    flex-direction: column; /* Stack elements vertically */
  }

  .department-semester-line,
  .installment-date-button-line {
    flex-direction: column; /* Stack on small screens */
    width: 100%;
  }

  .send-all-button {
    min-width: auto; /* Remove min-width constraint on small screens */
  }

  .student-details-table th, 
  .student-details-table td {
    font-size: 12px;
    padding: 8px;
  }

  .pay-button, 
  .email-button {
    font-size: 12px;
    padding: 5px;
  }
}

/* For very small screens - 480px or less */
@media (max-width: 480px) {
  .student-details-table {
    font-size: 12px;
  }

  .filter-container {
    flex-direction: column; /* Stack elements vertically on very small screens */
  }

  .filter-container label, 
  .filter-container select, 
  .filter-container input,
  .send-all-button {
    width: 100%; /* Full width for small screens */
  }

  .student-details-table th, 
  .student-details-table td {
    font-size: 10px;
    padding: 6px;
  }

  .pay-button, 
  .email-button {
    font-size: 10px;
    padding: 5px;
  }
}
.table-responsive {
  overflow-x: auto; /* Enable horizontal scrolling */
  -webkit-overflow-scrolling: touch; /* Enable smooth scrolling on iOS */
}

.student-details-table {
  min-width: 600px; /* Ensure the table has a minimum width to enable scrolling */
}
