/* Add this CSS for styling the filters to be on the same line */

.student-performance-container {
    margin: 100px 20px 20px;
    padding: 20px;
    font-family: Arial, sans-serif;
  }
  
  .selectors-container {
    display: flex; /* Arrange child elements in a row */
    gap: 20px; /* Adds space between course and semester filters */
    align-items: center; /* Aligns items vertically in the center */
    margin-bottom: 20px; /* Adds space below the filter section */
  }
  
  .course-selector, .semester-selector {
    display: flex;
    flex-direction: column; /* Aligns label above select */
  }
  
  .course-selector label,
  .semester-selector label {
    font-size: 0.9rem;
    margin-bottom: 5px; /* Space between label and dropdown */
  }
  
  .course-selector select,
  .semester-selector select {
    padding: 5px;
    font-size: 1rem;
    border: 1px solid #ddd;
    border-radius: 4px;
  }
  
  .table-container {
    margin-top: 20px;
    overflow-x: auto; /* Makes the table responsive */
  }
  
  .student-performance-table {
    width: 100%;
    border-collapse: collapse;
    margin: 20px 0;
    font-size: 0.9rem; /* Slightly smaller font size for better appearance */
  }
  
  .student-performance-table, 
  .student-performance-table th, 
  .student-performance-table td {
    border: 1px solid #ddd;
  }
  
  .student-performance-table th, 
  .student-performance-table td {
    padding: 10px;
    text-align: left;
  }
  
  .student-performance-table th {
    background-color: #f4f4f4;
    font-weight: bold;
  }
  
  .student-performance-table tr:nth-child(even) {
    background-color: #f9f9f9;
  }
  
  .student-performance-table tr:hover {
    background-color: #f1f1f1;
  }
  