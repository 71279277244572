.admin-user-management {
  padding: 20px;
  max-width: 1200px;
  margin: 0 auto;
}

.user-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 20px;
}

.user-card {
  background: rgb(208, 208, 208);
  padding: 15px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  flex: 1 1 calc(33.333% - 20px);
  box-sizing: border-box;
}

.user-card p {
  margin: 10px 0;
}

.user-card input {
  width: calc(100% - 20px);
  padding: 10px;
  margin: 5px 0;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.user-card button {
  padding: 10px 20px;
  margin: 10px 5px 0 0;
  border: none;
  border-radius: 4px;
  color: white;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.user-card button:nth-of-type(1) {
  background-color: #4CAF50;
}

.user-card button:nth-of-type(2) {
  background-color: #f44336;
}

.user-card button:hover {
  opacity: 0.8;
}

@media (max-width: 768px) {
  .user-card {
    flex: 1 1 calc(50% - 20px);
  }
}

@media (max-width: 480px) {
  .user-card {
    flex: 1 1 100%;
  }
}

@media (max-width: 360px) {
  .admin-user-management {
    padding: 10px;
  }

  .user-card {
    padding: 10px;
  }

  .user-card input,
  .user-card button {
    width: 100%;
    margin: 5px 0;
  }
}
