/* Performance.css */

.student-performance-container {
  margin: 100px 20px 20px 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.student-performance-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 0px;
}

.student-performance-table th,
.student-performance-table td {
  border: 1px solid #ddd;
  padding: 10px;
  text-align: center;
}

.table-container {
  overflow-x: auto;
}

@media screen and (max-width: 768px) {
  .student-performance-table th,
  .student-performance-table td {
    font-size: 14px;
    padding: 8px;
  }
}
