
.installment-container {
  max-width: 5000px;
  margin: 0 auto;
  padding: 100px;
  background-color: #fff;
  border-radius: 10px;
  min-height: calc(100vh - 160px); /* Adjust this value if needed */
}

.installment-container h2 {
  text-align: center;
  color: #333;
  margin-bottom: 20px;
}

.error {
  color: red;
  text-align: center;
  margin-bottom: 20px;
}

.installment-list {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.installment-item {
  width: 100%;
  max-width: 600px;
  background-color: #f9f9f9;
  border: 1px solid #ccc;
  padding: 15px;
  margin: 10px 0;
  border-radius: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: background-color 0.3s;
}

.installment-item:hover {
  background-color: #e9e9e9;
}

.paid {
  color: green;
  font-weight: bold;
}

.not-paid {
  color: red;
  font-weight: bold;
}

.installment-container p {
  font-size: 1.1rem;
  margin: 5px 0;
}
