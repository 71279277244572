/* TeacherPage.css */

.teacher-display-container {
    margin: 100px 20px 20px;
    padding: 20px;
    margin-left: 50px;
    font-family: Arial, sans-serif;
}


label {
    font-size: 1rem;
    margin-right: 10px;
}

select {
    padding: 5px;
    font-size: 1rem;
    border: 1px solid #ddd;
    border-radius: 4px;
}

.teacher-table-container {
    overflow-x: auto;
    margin: 20px;
    border-radius: 8px;
    border: 1px solid #ddd;
}

.Teachertable {
    width: 100%;
    border-collapse: collapse;
    font-size: 0.9rem;
}

.Teachertable, th, td {
    border: 1px solid #ddd;
}

th, td {
    padding: 10px;
    text-align: left;
}

th {
    background-color: #f4f4f4;
    font-weight: bold;
}

tr:nth-child(even) {
    background-color: #f9f9f9;
}

tr:hover {
    background-color: #f1f1f1;
}
