/* src/components/Footer.css */
.footer {
    background-color: #1a1a1a; /* Dark background */
    color: #ccc; /* Soft text color */
    padding: 20px 0;
    text-align: center;
    font-family: 'Roboto', sans-serif; /* Modern font */
    border-top: 4px solid #ffcc00;
    bottom: -1000px; /* Accent border */
  }
  
  .footer-content {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 20px;
  }
  
  .footer p {
    margin: 8px 0;
    font-size: 1rem;
    line-height: 1.5;
  }
  
  .highlight {
    color: #ffcc00; /* Highlight color */
    font-weight: bold;
  }
  .app-container {
    min-height: 500vh;
    display: flex;
    flex-direction: column;
  }

  @media (max-width: 768px) {
    .footer p {
      font-size: 0.9rem;
    }
  }
  