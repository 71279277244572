.container {
  margin: 100px 20px 20px;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 8px;
  background-color: #f9f9f9;
}

.label {
  display: block;
  margin-bottom: 8px;
  font-weight: bold;
  color: #333;
}

.select, .inputText {
  width: 100%;
  padding: 10px;
  margin-bottom: 15px;
  border: 1px solid #ddd;
  border-radius: 6px;
  box-sizing: border-box;
}

.form {
  display: flex;
  flex-direction: column;
}

.subjectRow {
  display: flex;
  justify-content: space-between;
  margin-bottom: 12px;
  align-items: center;
}

.inputText {
  flex: 1;
  margin-right: 15px;
}

.removeButton, .addButton, .submitButton, .showFormButton {
  cursor: pointer;
  border: none;
  border-radius: 6px;
  padding: 8px 12px;
  font-size: 16px;
  transition: all 0.3s ease;
}

.removeButton {
  color: #e3342f;
  background: #fff;
}

.removeButton:hover {
  color: #c53030;
  background-color: #f8d7da;
}

.addButton {
  color: #38c172;
  background: #3826dc;
}

.addButton:hover {
  color: #1a297f;
  background-color: #e6fffa;
}

.submitButton, .showFormButton {
  color: #fff;
  background-color: #00ff3c;
}

.submitButton:hover, .showFormButton:hover {
  background-color: #138928;
}

.table {
  width: 100%;
  margin-top: 20px;
  overflow-x: auto;
}

.table table {
  width: 100%;
  border-collapse: collapse;
}

.table th, .table td {
  padding: 12px;
  border: 1px solid #ddd;
  text-align: left;
  color: #333;
}

.loading {
  text-align: center;
  font-size: 18px;
  color: #007bff;
  font-weight: bold;
}
