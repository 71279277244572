.signup-background-image {
    background-color: white;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .signup-container {
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    width: 400px;
  }
  
  
  
  .signup-form {
    padding: 20px;
  }
  
  .signup-fill{
    padding: 20px;
  }
  
  .signup-avatar {
    margin-top: 15px;
    text-align: center;
  }
  
  .signup-avatar img {
    width: 80px;
    height: 80px;
    border-radius: 50%;
  }
  
  h2 {
    text-align: center;
    color: white;
  }
  
  .password-input-container {
    position: relative;
  }
  
  .eye-icon {
    position: absolute;
    top: 40%;
    right: 10px;
    transform: translateY(-50%);
    cursor: pointer;
    color: #777; /* Adjust the color as needed */
  }
  
  
  input {
    width: 100%;
    padding: 10px;
    margin: 8px 0;
    display: inline-block;
    border: 1px solid #ccc;
    box-sizing: border-box;
    border-radius: 5px;
    font-size: 16px;
  }
  
  .signup-password-field {
    margin-bottom: 15px;
  }
  
  label {
    display: block;
    margin-bottom: 8px;
    color: #555;
  }
  
  span {
    display: block;
    margin-top: 8px;
    cursor: pointer;
    color: #060606e4;
  }
  
  .signup-button-container {
    margin-top: 30px;
    text-align: center;
  }
  
  .signup-button-container button {
    background-color: rgb(85, 145, 240);
    color: #fff;
    padding: 10px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    width: 50%;
    font-size: 16px;
    margin: 0 auto;
    margin-bottom: 20px;
  }
  
  .signup-button-container button:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }
  
  .signup-error-text {
    color: #252a31;
    margin-top: 8px;
    font-size: 14px;
    text-align: center;
  }

  .login-link p{
   text-align: center;
  }
  
  @media (max-width: 500px) {
    .signup-container {
      width: 90%;
    }
  }
  
  .signup-header-box {
    background-color: rgb(85, 145, 240);
    border-radius: 10px;
    padding: 5px;
    text-align: center;
    margin-bottom: 10px; /* Adjust as needed */
  }
  
  .login-link p{
    font-size: 15px;
  }
  
  .login-link p a {
    color: rgb(85, 145, 240); /* or any color you prefer */
    text-decoration: none; /* Remove underline */
    font-size: 15px;
  }
  
  .login-link p a:hover {
    text-decoration: underline; /* Add underline on hover if desired */
  }

  .password-input-container p{
    font-size: small;
    color: #5b5b5b;
  }