
/* Importing Google Fonts */
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@700&display=swap');

/* Base styles for body and html */
body, html {
  overflow-x: hidden; /* Prevent horizontal scrollbar on the entire page */
  font-family: Arial, sans-serif;
  background-color: #ffffff; /* Light cyan background */
  color: #333;
  line-height: 1.6;
  margin: 0;
  padding: 0;
}

.approvedandremoved-list-container{
  margin: 100px 20px 20px 20px;
}

/* Header container styling */
.header-container {
  text-align: center;
  margin: 30px 0 20px; /* Adjusted margin */
}
.page-title {
  font-size: 2em; /* Adjust size as needed */
  font-weight: bold;
  margin: 0;
  padding: 0;
  color: #333; /* Adjust color as needed */
  text-align: center; /* Center-aligns the text */
}



.header-container h3 {
  font-size: 3em;
  color: #333;
  margin-bottom: 10px;
  font-family: 'Roboto', sans-serif;
}

.header-container .divider {
  width: 80%;
  margin: 0 auto;
  border: 0;
  height: 1px;
  background: #ccc;
}

/* Container for data entry form */
.data-entry-container {
  width: 85%;
  padding: 0px 20px 0px 20px;
  background: #ffffff; /* White background for the form */
  margin: 80px auto 20px auto;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  box-sizing: border-box; /* Ensure padding is included in width */
}

/* Form elements */
.data-entry-container input,
.data-entry-container select,
.data-entry-container textarea {
  margin-top: 5px;
  height: 40px;
  width: 100%;
  padding: 10px;
  border: 1px solid #bbb; /* Lighter border */
  border-radius: 4px;
  font-size: 14px;
  transition: border-color 0.3s;
  box-sizing: border-box; /* Ensure padding is included in width */
}

.data-entry-container input:focus,
.data-entry-container select:focus,
.data-entry-container textarea:focus {
  border-color: #03a9f4; /* Soft blue focus color */
  outline: none;
}

/* Label styling */
.data-entry-container label {
  display: block;
  font-weight: bold;
  color: #030202; /* Dark grey color */
  margin-bottom: 5px;
}

/* Select box styling */
.data-entry-container select {
  color: #333;
  height: 40px;
}
/* Button styling */
.data-entry-container .capture-button ,.data-entry-container .upload-button {
  padding: 10px 20px; /* Ensure consistent padding */
  margin: 0 5px; /* Adjust margin */
  cursor: pointer;
  font-size: 16px;
  border: none;
  border-radius: 4px;
  transition: background-color 0.3s, transform 0.3s;
  box-sizing: border-box; /* Ensure padding is included in width */
  width: auto; /* Allow width to adjust based on content */
}

/* Ensure preview canvas does not affect button size */
.data-entry-container .preview-canvas {
  max-width: 100%; /* Adjust as needed */
  max-height: 100%; /* Adjust as needed */
}

.data-entry-container .button-container {
  display: flex;
  justify-content: space-evenly;
  margin-top: 20px;
}

.data-entry-container .submit-button {
  background-color: #03a9f4; /* Soft blue button */
  color: #fff;
}

.data-entry-container .submit-button:hover {
  background-color: #0288d1; /* Darker blue on hover */
  transform: scale(1.05);
}

.data-entry-container .clear-button {
  background-color: #f44336; /* Red button */
  color: #fff;
}

.data-entry-container .clear-button:hover {
  background-color: #d32f2f; /* Darker red on hover */
  transform: scale(1.05);
}

.data-entry-container .capture-button {
  background-color: #264fa9; /* Green button */
  color: #fff;
}

.data-entry-container .capture-button:hover {
  background-color: #3a30e9; /* Darker green on hover */
  transform: scale(1.05);
}

.data-entry-container .upload-button {
  background-color: #264fa9; /* Another green button */
  color: #fff;
}

.data-entry-container .upload-button:hover {
  background-color: #3a30e9;; /* Darker green on hover */
  transform: scale(1.05);
}

/* Box container styling */
.data-entry-container .box {
  width: 100%;
  background-color: #f0f0f0;
  padding: 15px;
  border-radius: 8px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  margin-bottom: 15px;
}

.data-entry-container .box-container {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

/* Heading styling */
.data-entry-container h4 {
  color: #1a1919;
  margin-bottom: 15px;
}

/* Photo preview styling */
.data-entry-container .photo-preview {
  width: 150px;
  height: 200px;
  margin-bottom: 30px;
  border: 1px solid #bbb; /* Lighter border */
  border-radius: 4px;
  object-fit: cover;
}

/* Form group styling */
.data-entry-container .form-group {
  margin-bottom: 15px;
}

/* Form container styling */
.data-entry-container .form {
  max-width: 100%;
  overflow-x: hidden;
  margin-top: 30px;
  margin-bottom: 30px;
}

/* Add some hover effects for inputs */
.data-entry-container input:hover,
.data-entry-container select:hover,
.data-entry-container textarea:hover {
  border-color: #03a9f4; /* Soft blue border on hover */
}

.data-entry-container .pincode-input {
  width: 100px; /* Adjust width as needed */
}

/* Enhance textarea for achievements */
.data-entry-container textarea {
  height: auto;
  resize: vertical;
  min-height: 100px;
}

/* Parent details section styling */
.parent-details-row {
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
}

.parent-details-row .form-group {
  flex: 1 1 calc(50% - 15px); /* Take full width on small screens, half on larger screens */
  min-width: 250px;
}
.qualified-details-row .form-group{
  flex: 1 1 calc(50% - 15px); /* Take full width on small screens, half on larger screens */
  min-width: 250px;
}
/* Styling for elements in a row */
.data-entry-container .row {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 15px;
}

.data-entry-container .row .form-group {
  flex: 1;
  min-width: 200px;
  margin-right: 10px;
}

.data-entry-container .row .form-group:last-child {
  margin-right: 0;
}

/* Styling for form headings */
.data-entry-container h1 {
  text-align: center;
  font-size: 3em;
  margin-bottom: 20px;
  color: #333;
}
/* Radio button styling */
.data-entry-container input[type="radio"] {
  height: 16px; /* Adjust height */
  width: 16px; /* Adjust width */
  margin-top: 10px; /* Adjust margin */
  -webkit-appearance: none; /* For older versions of Safari */
  appearance: none; /* Remove default appearance */
  border: 2px solid #757575; /* Default border color */
  border-radius: 50%; /* Rounded shape */
  outline: none; /* Remove outline on focus */
  cursor: pointer; /* Add cursor pointer */
}

.radio-label {
  margin-right: 2px; /* Adjust margin between radio button and text */
}

/* Radio button checked state */
.data-entry-container input[type="radio"]:checked {
  background-color: #03a9f4; /* Change background color when checked */
  border-color: #03a9f4; /* Change border color when checked */
}
.capture-button, .upload-button {
  width: 120px; /* Adjust width as needed */
  height: 40px; /* Adjust height as needed */
  font-size: 16px; 
}

.submitt-button{
  background-color: #1a4bd3;
}
.submitt-button, .clear-button
 {
  padding: 10px 20px;
  margin: 0 10px; /* Adjust margin between buttons */
  cursor: pointer;
  font-size: 16px;
  border: none;
  border-radius: 4px;
  transition: background-color 0.3s, transform 0.3s;
  box-sizing: border-box;
  width: auto;
  height: 40px;
  margin-bottom: 10px;
}

/* Loading Overlay */
.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.loading-spinner {
  border: 16px solid #f3f3f3; /* Light grey */
  border-top: 16px solid #3498db; /* Blue */
  border-radius: 50%;
  width: 120px;
  height: 120px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}


/* Optional: Adjust text style */
.loading-overlay p {
  color: #fff;
  font-size: 1.5rem;
  margin-top: 20px;
}
/* Style the container for checkboxes */
.checkbox-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

/* Hide default checkbox appearance */
.checkbox-container input[type="checkbox"] {
  display: none;
}

/* Style the custom checkbox container */
.checkbox-custom {
  display: flex;
  align-items: center;
  cursor: pointer;
  font-size: 16px;
  position: relative;
}

/* Style the box */
.checkbox-custom .checkmark {
  position: relative;
  width: 20px;
  height: 20px;
  border: 2px solid #0b0a0a;
  border-radius: 4px;
  background-color: #fff;
  margin-right: 10px; /* Space between checkbox and label */
  transition: background-color 0.3s, border-color 0.3s;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Hide the box when checked */
.checkbox-custom input[type="checkbox"]:checked + .checkmark {
  display: none;
}

/* Add a checkmark icon */
.checkbox-custom input[type="checkbox"]:checked + .checkmark::after {
  content: '';
  position: absolute;
  width: 8px;
  height: 14px;
  border: solid #4caf50;
  border-width: 0 3px 3px 0;
  transform: rotate(45deg);
  top: 50%; /* Center the checkmark vertically */
  left: 50%; /* Center the checkmark horizontally */
  margin-top: -7px; /* Adjust for vertical alignment */
  margin-left: -4px; /* Adjust for horizontal alignment */
}

/* Style the label text */
.checkbox-custom label {
  font-size: 16px;
}
.box {
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #f9f9f9;
  margin-bottom: 20px;
}

/* Style the heading */
.box h4 {
  margin: 0;
  font-size: 18px;
  margin-bottom: 10px;
}

/* Style the label inside the box */
.box label {
  display: block;
  font-size: 14px;
  margin-bottom: 10px;
  color: #666;
}

/* Style the container for the radio group row */
.radio-group-row {
  display: flex;
  gap: 20px; /* Space between each radio button */
}

/* Style each radio label */
.radio-group-row label {
  display: flex;
  align-items: center;
  cursor: pointer;
  font-size: 16px;
}

/* Hide default radio button appearance */
.radio-group-row input[type="radio"] {
  display: none;
}

/* Style the custom radio button */
.radio-group-row input[type="radio"] + span {
  position: relative;
  display: inline-block;
  width: 20px;
  height: 20px;
  border: 2px solid #0b0a0a;
  border-radius: 50%;
  background-color: #fff;
  margin-right: 8px; /* Space between custom radio and label text */
  transition: background-color 0.3s, border-color 0.3s;
}

/* Style the radio button when checked */
.radio-group-row input[type="radio"]:checked + span {
  background-color: #4caf50;
  border-color: #4caf50;
}

/* Add a checkmark when checked */
.radio-group-row input[type="radio"]:checked + span::after {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #fff;
}

/* Style the label text */
.radio-group-row label span {
  margin-left: 10px;
}
 label {
  display: block;
  font-size: 14px;
  margin-bottom: 10px;
  color: #666;
}
/* Styles for the modal overlay */
.modal-overlay {
  background: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex; /* Enable flexbox to center content */
  align-items: center; /* Center vertically */
  justify-content: center; /* Center horizontally */
}

/* Styles for the modal */
.modal {
  background: white;
  padding: 20px;
  border-radius: 4px;
  max-width: 500px;
  width: 90%; /* Responsive width */
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2); /* Optional: add shadow for better visibility */
  position: relative;
}


/* Style the required field with a red asterisk */
 label.required::after {
  content: '*';
  color: red;
  margin-left: 4px;
}
table{
width: 100%;
border-collapse: collapse;
margin-top: 20px;
 /* Ensure table scroll on smaller screens */
}