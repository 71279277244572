/* DataEditing.css */

/* Container for the filter */
.student-display-container{
  margin: 100px 20px 20px 20px;
}

.student-display-container button{
  margin-bottom: 10px;
  margin-right: 10px;
}
.delete-button {
  background-color: #ff4c4c; /* Red background for delete */
  color: #fff; /* White text color */
  border: none; /* No border */
  border-radius: 5px; /* Rounded corners */
 
  font-weight: 500; /* Medium weight font */
  cursor: pointer; /* Pointer cursor on hover */
  transition: background-color 0.3s, box-shadow 0.3s; /* Smooth transitions */

}

.delete-button:hover {
  background-color: #e43d3d; /* Darker red on hover */
}

.delete-button:active {
  background-color: #c13232; /* Even darker red on active */
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2); /* Slightly reduced shadow */
}

.delete-button:focus {
  outline: none; /* Remove default outline */
  box-shadow: 0 0 0 3px rgba(255, 76, 76, 0.4); /* Custom focus outline */
}



.filter-container {
    display: flex;
    align-items: center;
  }

  /* Label for the filter */
  .filter-label {
    font-size: 16px;
    margin-right: 10px;
    font-weight: 600;
  }
  .table-responsive {
    overflow-x: auto; /* Enable horizontal scrolling */
    -webkit-overflow-scrolling: touch; /* Enable smooth scrolling on iOS */
  }
  
  
  
  /* Dropdown styling */
  .filter-dropdown {
    margin-top: 0px;
    margin-bottom: 20px;
    padding: 8px 12px;
    border-radius: 4px;
    border: 1px solid #ddd;
    font-size: 16px;
    background-color: #fff;
    transition: border-color 0.2s ease-in-out;
  }
  
  /* Dropdown focus effect */
  .filter-dropdown:focus {
    border-color: #007bff;
    outline: none;
    box-shadow: 0 0 0 2px rgba(38, 143, 255, 0.2);
  }
  
  /* Additional styling for dropdown options */
  .filter-dropdown option {
    padding: 8px 12px;
  }
.aluminibtn{
  background-color: #1a4bd3;
}
.edit-btn{
  background-color:rgb(9, 145, 32);
  
}
.print{
  background-color: rgb(86, 136, 180);
}