.certificate-requests{
    margin: 100px auto 20px auto;
  width:80%;
  padding: 0px 20px 20px 20px;
    border: 1px solid #ddd;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.certificate-requests h1{
    font-size: large;
    color: black;
}