/* reminders.css */

.reminder-page-container {
  max-width: 800px;
  margin: 100px auto;
  padding: 40px 20px 20px 20px;
}

.reminder-category {
  margin-bottom: 30px;
}

.assignment-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}

.assignment-table th,
.assignment-table td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}

.assignment-table th {
  background-color: #f2f2f2;
}

.assignment-table tbody tr:nth-child(even) {
  background-color: #f2f2f2;
}

.attendance-container {
  display: flex;
  flex-wrap: wrap;
}

.attendance-item {
  margin-right: 20px;
  margin-bottom: 20px;
}

.attendance-circle {
  position: relative;
  width: 80px;
  height: 80px;
  border-radius: 50%;
  overflow: hidden;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
}

.attendance-circle svg {
  position: absolute;
  width: 100%;
  height: 100%;
  transform: rotate(-90deg); /* Start from the top */
}

.attendance-circle circle {
  fill: none;
  stroke-width: 8;
  stroke-linecap: round;
  transform: translate(5px, 5px); /* Adjust position */
}

.attendance-circle circle.background {
  stroke: #eee; /* Light gray for background */
}

.attendance-circle circle.foreground {
  stroke: #2196f3; /* Blue color for positive */
  stroke-dasharray: 0, 100;
  animation: progress 1s ease forwards; /* Animation for progress */
}

.attendance-info {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  width: 100%;
}

.attendance-text {
  font-size: 14px;
  font-weight: bold;
  color: #555;
}

.updates {
  list-style-type: none;
  padding: 0;
}

.updates li {
  margin-bottom: 10px;
  background-color: #f9f9f9;
  padding: 10px;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s ease;
}

.updates li:hover {
  background-color: #e0e0e0;
}
h2 {
  text-align: center;
  color: rgb(0, 0, 0);
}

