/* Updates.css */

.update-container {
  margin: 100px auto 20px auto;
  width: 60%;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  background-color: #f9f9f9;
}

.update-container label {
  display: block;
  margin-bottom: 15px;
  font-weight: bold;
}

.update-container textarea {
  width: 100%;
  height: 100px;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  resize: vertical;
  margin-bottom: 20px;
}

.student-selection {
  margin-bottom: 20px;
}

.select-all {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}

.select-all label {
  margin-left: 10px;
  font-weight: normal;
}

.student-checkbox {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  width: 100%;
  padding: 10px;
  border-bottom: 1px solid #ddd;
}

.student-checkbox span {
  flex: 1;
  font-size: 16px;
}

.student-checkbox input[type="checkbox"] {
  margin-left: 10px;
}

.submit-button {
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin-top: 10px;
  display: block;
  width: 100%;
}

.submit-button:hover {
  background-color: #0056b3;
}

.error-message, .success-message {
  font-size: 14px;
  padding: 10px;
  border-radius: 4px;
  margin-top: 20px;
  text-align: center;
}

.error-message {
  background-color: #f8d7da;
  color: #721c24;
  border: 1px solid #f5c6cb;
}

.success-message {
  background-color: #d4edda;
  color: #155724;
  border: 1px solid #c3e6cb;
}
