.forgot-password-body {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  margin: 0;
}

.forgot-password-container {
  max-height: 500px;
  max-width: 400px;
  margin: auto;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.forgot-password-container h2 {
  text-align: center;
}

.forgot-password-container form {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.forgot-password-container label {
  display: block;
  margin-bottom: 5px;
}

.forgot-password-container input {
  width: 100%;
  padding: 8px;
  box-sizing: border-box;
}

.forgot-password-container button {
  padding: 10px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.forgot-password-container button:hover {
  background-color: #0056b3;
}

.forgot-password-container p {
  margin-top: 10px;
  font-size: 14px;
  color: red;
}

  