.admin-dashboard {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  font-family: 'Arial', sans-serif;
  background-color: #f4f4f4;
  min-height: 100vh;
}

.dashboard-header {
  margin-bottom: 20px;
}

.stats-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
  margin-bottom: 20px;
}

.stat-card {
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
  text-align: center;
  width: 150px;
}

.stat-card h3 {
  margin-bottom: 10px;
  font-size: 1.2em;
}

.stat-card p {
  font-size: 1.5em;
  color: #333;
}

.chart-container {
  width: 100%;
  max-width: 800px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
}
