/* Global Styles */

.office-home-page {
  width: 100%;
  min-height: 100vh; /* Set minimum height to viewport height */
  background-color: rgba(0, 0, 0, 0.5);
  background-image: url('/public/images/collage.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden; /* Prevent sidebar from appearing */
  position: relative; /* Required for backdrop-filter */
  z-index: 0; /* Ensure the blur is behind other content */
}

.office-home-page {
  position: relative;
  padding: 20px;
}

.admission-button {
  position: absolute;
  top: 20px;
  right: 20px;
  padding: 10px 20px;
  font-size: 16px;
  background-color: #007BFF;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  z-index: 1000;
}

.admission-button:hover {
  background-color: #0056b3;
}

/* Hide the scrollbar on the home page */
html.homepage, body.homepage {
  overflow: hidden;
}

/* Blurred Background */
.office-home-page::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url('/public/images/collage.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  filter: blur(7px); /* Adjust the blur intensity as needed */
  opacity: 0.5; /* Adjust the background image opacity */
  z-index: -1; /* Place the blurred background behind other content */
}

/* Heading Styles */
.office-home-page h1 {
  font-size: 67.5px;
  text-align: center;
  font-weight: 600;
  color: rgba(215, 230, 240, 0.85);
  margin: 0 auto;
  margin-left: 30px;
  margin-right: 30px;
  animation: slide-from-left 1s ease-in-out forwards;
}

.office-home-page h5 {
  font-size: 30px;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.60);
  font-style: italic;
  margin: 0 auto;
  margin-left: 30px;
  margin-right: 30px;
  animation: slide-from-right 1s ease-in-out forwards;
}

/* Typing Animation Container */
.typing-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%; /* Ensure the container takes full width */
  margin-top: 20px;
}

/* Typing Animation Text */
.office-home-page h6 {
  font-size: 45px;
  font-weight: 500;
  color: rgb(220, 210, 210);
  white-space: nowrap; /* Prevent the text from wrapping */
  overflow: hidden; /* Ensure the overflow is hidden for the typing effect */
  border-right: 4px solid; /* Add a caret */
  width: 12ch; /* Adjust based on the length of the text */
  animation: typing 2s steps(12, end) forwards, blink-caret 0.75s step-end infinite;
  height: 1.2em; /* Set the height of the line here */
  line-height: 1.2em; /* Set the line height to match the height of the line */
}

/* Dropdown Container */
.office-home-page .dropdown-container {
  margin-top: 30px;
  background: linear-gradient(to bottom right, rgba(255, 255, 255, 0.9), );
  border: 2px solid rgba(0, 0, 0, 0.1);
  border-radius: 30px;
  box-shadow: 0 8px 16px rgba(236, 236, 236, 0.1);
  overflow: hidden;
  transition: all 0.3s ease;
}

/* Dropdown Select */
.office-home-page select {
  width: 200px;
  height: 40px;
  font-size: 1em;
  border: none;
  border-radius: 10px;
  padding: 10px;
  cursor: pointer;
  outline: none;
  background-color: rgba(255, 255, 255, 0.9);
  color: rgb(35, 35, 35);
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.office-home-page select:hover {
  background-color: rgba(255, 255, 255, 1);
  transform: scale(1.05);
}

/* Animation Classes */
@keyframes slide-from-left {
  from {
    transform: translateX(-100%);
  }
  to {
    transform: translateX(0);
  }
}

@keyframes slide-from-right {
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(0);
  }
}

@keyframes typing {
  from {
    width: 0;
  }
  to {
    width: 12ch;
  }
}

@keyframes blink-caret {
  from, to {
    border-color: transparent;
  }
  50% {
    border-color: white;
  }
}

/* Responsive Styles */
@media (max-width: 700px) {
  .office-home-page h1 {
    font-size: 45px;
  }
  
  .office-home-page h5 {
    font-size: 20px;
    text-align: center; /* Center align the text */
    margin: 0 auto; /* Center horizontally using margin */
    max-width: 80%; /* Limit the width to prevent stretching */
    word-break: break-word; /* Break words to ensure proper wrapping */
  }
  
  .office-home-page h6 {
    font-size: 30px;
  }
  
  .admission-button {
    position: absolute; /* Position the button absolutely */
    bottom: 800px; /* Adjust the distance from the bottom edge */
    right: 20px; /* Adjust the distance from the right edge */
    padding: 6px 12px; /* Further reduced padding for smaller screens */
    font-size: 12px; /* Smaller font size for smaller screens */
    background-color: #1d27e7; /* Optional background color */
    color: white; /* Optional text color */
    border: none; /* Optional border removal */
    border-radius: 5px; /* Optional rounded corners */
    cursor: pointer; /* Indicate clickable button */
  }
}
