.attendance-form {
  margin: 100px auto;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  max-width: 800px;
  box-sizing: border-box;
}

.attendance-form form {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.attendance-form label {
  font-size: 16px;
  color: #333;
  display: flex;
  flex-direction: column;
}

.attendance-form select, 
.attendance-form input[type="date"], 
.attendance-form button {
  padding: 10px;
  font-size: 14px;
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 100%;
  box-sizing: border-box;
}

.attendance-form button {
  background-color: #4CAF50;
  color: white;
  border: none;
  padding: 12px 15px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.attendance-form button:hover {
  background-color: #45a049;
}

/* Ensure the table's container is scrollable */
.atttable {
  overflow-x: auto;
  margin-top: 20px;
}

/* Remove width from the table to allow it to exceed the screen width */
.atttable table {
  width: 100%;
  border-collapse: collapse;
  min-width: 600px; /* This ensures scrolling is triggered */
  display: block;
}

.atttable table th, .atttable table td {
  padding: 12px 15px;
  text-align: left;
  border-bottom: 1px solid #ddd;
  font-size: 14px;
}

.atttable table th {
  background-color: #f2f2f2;
  font-weight: bold;
}

.atttable table td {
  font-size: 14px;
}

input[type="radio"] {
  margin-right: 8px;
}

/* Responsiveness for small screens */
@media (max-width: 768px) {
  .attendance-form {
    margin: 50px auto;
    padding: 15px;
    max-width: 100%;
  }

  .attendance-form label {
    font-size: 14px;
  }

  .attendance-form select, 
  .attendance-form input[type="date"], 
  .attendance-form button {
    font-size: 13px;
    padding: 8px;
  }

  /* Ensure table is scrollable on small devices */
  .atttable {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; /* Smooth scrolling for touch devices */
  }

  /* Remove 100% width to allow table to scroll */
  .atttable table {
    display: block;
    min-width: 800px; /* Trigger scrolling by setting min-width larger than viewport */
  }

  .atttable table th, .atttable table td {
    padding: 8px 10px;
    font-size: 12px;
  }

  .attendance-form button {
    font-size: 14px;
    padding: 10px;
  }
}

/* For very small screens (e.g., phones) */
@media (max-width: 480px) {
  .attendance-form {
    padding: 10px;
  }

  .attendance-form select, 
  .attendance-form input[type="date"], 
  .attendance-form button {
    font-size: 12px;
    padding: 7px;
  }

  .atttable table th, .atttable table td {
    padding: 6px 8px;
    font-size: 11px;
  }

  .attendance-form button {
    font-size: 13px;
    padding: 8px;
  }
}
