/* Styles for the form */
.formContainer {
  margin: 100px auto 20px auto;
  width:80%;
  padding: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: #f9f9f9;
  }
  
  .formContainer label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
  }
  
  .formContainer select, .formContainer input[type="text"] {
    width: calc(100%); /* Adjust width minus padding */
    padding: 8px;
    margin-bottom: 10px;
    border: 1px solid #ccc;
    border-radius: 3px;
    font-size: 14px;
  }
  
  .formContainer button {
    margin-top: 10px;
    padding: 8px 12px;
    background-color: #4CAF50;
    color: white;
    border: none;
    border-radius: 3px;
    cursor: pointer;
    font-size: 14px;
    width:25%;
  }
  
  .formContainer button:hover {
    background-color: #45a049;
  }
  
  /* Styles for the table */
  .tableContainer {
    margin: 20px;
  }
  .tableContainer{
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; /* Smooth scrolling for touch devices */
  }
  
  .tableContainer table {
    width: 100%;
    border-collapse: collapse;
  }
  
  .tableContainer th, .tableContainer td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;
    font-size: 14px;
  }
  
  .tableContainer th {
    background-color: #f2f2f2;
    font-weight: bold;
  }
  
  /* Print styles */
  @media print {
    body * {
      visibility: hidden;
    }
  
    .printTable, .printTable * {
      visibility: visible;
    }
  
    .printTable {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
    }
  
    .printButton {
      display: none;
    }
  }
  