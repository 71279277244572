/* OfficerCertificateRequestsPage.css */
   .certificate-distribution-container{
    margin: 100px 20px 20px 20px;
   }

  .request-list {
    padding: 10px;
    margin-top: 0px;
  }
  
  .request-list > div {
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 10px;
    margin-bottom: 10px;
  }
  
  .request-list > div > h3 {
    margin-bottom: 5px;
  }
  
  .request-list > div > p {
    font-size: medium;
    color: rgb(77, 77, 77);
    margin: 5px 0;
  }
  
  .certificate-requests input[type='file'] {
    margin-top: 10px;
  }
  
  .certificate-requests textarea {
    width: 100%;
    margin-top: 10px;
    resize: vertical; /* Allow vertical resizing of textarea */
  }
  
  .certificate-requests button {
    margin-top: 10px;
    padding: 5px 10px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 3px;
    cursor: pointer;
  }
  
  .certificate-requests button:hover {
    background-color: #0056b3;
  }
  
  /* Responsive Styles */
  @media (max-width: 768px) {
    .certificate-requests {
      padding: 10px;
    }
  
    .request-list > div {
      padding: 8px;
    }
  
    .certificate-requests input[type='file'],
    textarea,
    button {
      margin-top: 8px;
    }
  }