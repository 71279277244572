.student-details-container {
  margin: 100px 20px 20px;
  padding: 20px;
  font-family: Arial, sans-serif;
}

.student-details-title {
  color: #333;
  margin-bottom: 20px;
}

.student-details-filters {
  display: flex;
  align-items: center;
  gap: 20px; /* Adds space between filters */
  margin-bottom: 20px; /* Adds space below the filter section */
}

.student-details-label-select {
  display: flex;
  flex-direction: column;
  margin: 0;
}

.student-details-label-select label {
  font-size: 0.9rem;
  margin-bottom: 5px;
}

.student-details-label-select select {
  padding: 5px;
  font-size: 1rem;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.student-details-table-container {
  margin-top: 20px;
  overflow-x: auto; /* Keeps the table responsive */
}

.student-details-table {
  width: 100%;
  border-collapse: collapse;
  margin: 20px 0;
  font-size: 0.9rem; /* Slightly smaller font size for better appearance */
}

.student-details-table, 
.student-details-table th, 
.student-details-table td {
  border: 1px solid #ddd;
}

.student-details-table th, 
.student-details-table td {
  padding: 10px;
  text-align: left;
}

.student-details-table th {
  background-color: #f4f4f4;
  font-weight: bold;
}

.student-details-table tr:nth-child(even) {
  background-color: #f9f9f9;
}

.student-details-table tr:hover {
  background-color: #f1f1f1;
}
