.attendance-form {
  margin: 100px auto;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  max-width: 600px; /* Centered and contained for larger screens */
}

.attendance-form form {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.attendance-form label {
  font-size: 16px;
  color: #333;
  display: flex;
  flex-direction: column;
}

.attendance-form select, 
.attendance-form input[type="date"], 
.attendance-form button {
  padding: 10px;
  font-size: 14px;
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 100%;
  box-sizing: border-box;
}

.attendance-form button {
  background-color: #4CAF50;
  color: white;
  border: none;
  padding: 12px 15px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.attendance-form button:hover {
  background-color: #45a049;
}

.att table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
  overflow-x: auto; /* Enable horizontal scroll for tables */
  display: block; /* Keep table responsive */
}

.att table th, table td {
  padding: 12px 15px;
  text-align: center; 
  border-bottom: 1px solid #ddd;
  font-size: 14px;
}

.att table th {
  background-color: #f2f2f2;
  font-weight: bold;
}

.att table td {
  font-size: 14px;
}

input[type="checkbox"] {
  margin-right: 8px;
}
.allow-multiple-hours-container {
  display: inline-flex; /* To keep the labels and checkbox in the same line */
  align-items: center; /* Vertically aligns the labels and checkbox */
  gap: 20px; /* Adjust space between label and checkbox */
}

.allow-multiple-hours,
.allow-multiple {
  margin: 0; /* Remove any extra margin */
  font-size: 16px; /* Adjust font size as needed */
}

.allow-multiple input[type="checkbox"] {
  margin: 0; /* Remove default margin on checkbox */
}


/* Add responsiveness for small screens */
@media (max-width: 768px) {
  .attendance-form {
    margin: 100px 20px 0px 20px; /* No margin */
    padding: 15px;
    background-color: transparent; /* Remove background color */
    border-radius: 0; /* Remove border radius */
    box-shadow: none; /* Remove shadow */
    max-width: 100%; /* Take full width of the screen */
  }

  .attendance-form label {
    font-size: 14px;
  }

  .attendance-form select, 
  .attendance-form input[type="date"], 
  .attendance-form button {
    font-size: 13px;
    padding: 8px;
  }

  /* Reduce padding and font size for table cells on smaller screens */
  .att table th, table td {
    padding: 8px 10px;
    font-size: 12px;
  }

  /* Horizontal scrolling for small devices */
  .att table {
    display: block;
    width: 100%;
    overflow-x: auto; /* Allows horizontal scroll on small screens */
  }

  .att table th, table td {
    white-space: nowrap; /* Prevent text from breaking */
  }

  /* Adjust button for smaller screens */
  .attendance-form button {
    font-size: 14px;
    padding: 10px;
  }
}

/* Additional responsiveness for very small screens (phones) */
@media (max-width: 480px) {
  .attendance-form {
    margin: 100px 20px 0px 20px; /* Take full width */
    padding: 10px;
    background-color: transparent; /* Remove box background */
    border-radius: 0;
    box-shadow: none;
  }

  .attendance-form select, 
  .attendance-form input[type="date"], 
  .attendance-form button {
    font-size: 12px;
    padding: 7px;
  }

  .att table th, table td {
    padding: 6px 8px;
    font-size: 11px;
  }

  /* Ensure table scroll works well on mobile */
  .att table {
    width: 100%;
    display: block;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; /* Smooth scrolling for mobile */
  }

  /* Keep buttons usable on small screens */
  .attendance-form button {
    font-size: 13px;
    padding: 10px;
  }
}
